import React, { useEffect } from 'react';
import { analytics } from './firebase'; // Make sure to create this file
import { logEvent } from 'firebase/analytics';
import './App.css';

function App() {
  useEffect(() => {
    logEvent(analytics, 'page_view'); 
  }, []);

  const handleAndroidClick = () => {
    logEvent(analytics, 'android_download_click');
  };

  const handleIOSClick = () => {
    logEvent(analytics, 'ios_download_click');
  };

  return (
    <div className="App">
      <div className="content">
        <h1 className="title">Exatest: Supercharge Your Mental Math</h1>
        <h2 className="subtitle">Boost Your Brain Power in Just 120 Seconds a Day!</h2>
        <div className="features-box">
          <div className="feature-item">
            <span className="emoji">🚀</span>
            <p>Accelerate your mental arithmetic speed</p>
          </div>
          <div className="feature-item">
            <span className="emoji">🏆</span>
            <p>Challenge friends with Daily Mode</p>
          </div>
          <div className="feature-item">
            <span className="emoji">📊</span>
            <p>Track your progress with detailed charts</p>
          </div>
          <div className="feature-item">
            <span className="emoji">💼</span>
            <p>Prepare for trading interviews like a pro</p>
          </div>
          <div className="feature-item">
            <span className="emoji">🌈</span>
            <p>Enjoy multiple themes to keep it fresh</p>
          </div>
        </div>
        <p className="tagline">Ready to transform your mind into a math powerhouse?</p>
        <div className="button-container">
          <a 
            href="https://play.google.com/store/apps/details?id=com.exatest" 
            className="app-badge"
            onClick={handleAndroidClick}
          >
            <img src={`${process.env.PUBLIC_URL}/playstore.png`} alt="Get it on Google Play" />
          </a>
          <a 
            href="https://apps.apple.com/us/app/exatest-arithmetic-speed-drill/id1601075819" 
            className="app-badge"
            onClick={handleIOSClick}
          >
            <img src={`${process.env.PUBLIC_URL}/appstore.svg`} alt="Download on the App Store" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;