// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCyG6KWgwL5PirzGc0dLLoOuU9C0J2BB28",
  authDomain: "exatest-749d9.firebaseapp.com",
  projectId: "exatest-749d9",
  storageBucket: "exatest-749d9.appspot.com",
  messagingSenderId: "16750124902",
  appId: "1:16750124902:web:e9d079c6a03fda98adb2a7",
  measurementId: "G-EH2BEXD1VB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);